// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require.context('../images', true)
import '@css/application.scss'

import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'select2'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'js/download_app'
import 'js/carousel'
import 'js/form_select2'
import 'js/form_reset_select2'
import 'js/scroll_top'
import 'js/google-ad'
import 'js/common.js'
