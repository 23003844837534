import $ from 'jquery'

const SELECTOR = '[data-scroll-top]'

// 因沒有共同規則，所以特別獨立設定
const targetPositions = {
  ranking: {
    mobile: () => {
      const head = document.querySelector('.mobile-header')
      const el = document.querySelector('.section-ranking .section-title')
      if (!(head && el)) { return; }

      return (el.offsetTop + el.offsetHeight - head.offsetHeight)
    },
    desktop: () => {
      const el = document.querySelector('.section-ranking .section-title')
      return el.offsetTop
    }
  }
}

class ScrollTop {
  constructor(dom) {
    this.$dom = $(dom)
    this.target = this.$dom.data('target') || ''

    this.$dom.on('click', () => {
      if (this.target && targetPositions[this.target]) {
        const pos = targetPositions[this.target][this._curDevice()]()
        window.scrollTo(0, pos)
      } else {
        window.scrollTo(0, 0)
      }
    })
  }

  _curDevice() {
    return (window.innerWidth < 768 ? 'mobile' : 'desktop')
  }
}

$(document).on('turbolinks:load', function(){
  document.querySelectorAll(SELECTOR).forEach(dom => {
    new ScrollTop(dom)
  })
})

export default ScrollTop
