const checkReviewContent = () => {
  if ($('.product-review-block .content-desc-block .content .content-detail, .review-pickup .content-block').length > 0) {
    $.each($('.product-review-block .content-desc-block .content .content-detail, .review-pickup .content-block'), (idx, el) => {
      let overLength = $(el).data('max-length') ?  $(el).text().length > +$(el).data('max-length') : false
      if (el.scrollHeight > el.clientHeight || overLength ) {
        $(`#${$(el).attr('data-rid')}more-content`).css("display","block")
      }
    })
  }
}




$(document).on('turbolinks:load', function(){
  checkReviewContent()
  if ($('.list-group-item .list-main-mobile-items').length > 0) {
    $('.list-group-item .list-main-mobile-items').on('click', function(e) {
      switch (e.target.className.split(" ")[0]) {
        case "list-main-btn":
          $(e.target.parentElement).toggleClass('active')
          break;
        case "menu-arrow":
          $(e.target.parentElement).toggleClass('active')
          break;
        default:
          $(e.target).toggleClass('active')
          break;
      }
    })
  }

  $('.tips-note').tooltip({show: null, tooltipClass: "tooltips-block"});

  $('.product_upgrate_cover .close-btn').click(function(){
    $('.product_upgrate_cover').remove();
  });

})
