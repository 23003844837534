import $ from 'jquery'
import Turbolinks from "turbolinks"

// 套用select2
// attrs:
//  data-form-select2
//  data-placeholder
//  data-trigger-type: [url]
//  data-trigger-baseurl
//
//  data-variables: { brand: 200, category: 'xxx' }
//    例如：data-trigger-baseurl="products?brand=#{brand}&category=#{category}"
//      #{brand} 會被 200 取代
//
//  data-variable-selectors: { "brand": 'input[name="brand"]', "category": 'input[name="category"]' }
//    例如：data-trigger-baseurl="products?brand=#{brand}&category=#{category}"
//      #{brand} 會被 $('input[name="brand"]').val() 取代

const SELECTOR = '[data-form-select2]'
const FORM_ID = '#search-form'

class FormSelect2 {
  constructor(dom) {
    this.$dom = $(dom)
    this.allow_clear = this.$dom.data('allow-clear') == 'true' ;
    this.placeholder = this.$dom.data('placeholder') || ''
    this.triggerType = this.$dom.data('trigger-type')
    this.triggerBaseurl = this.$dom.data('trigger-baseurl') || ''
    this.variables = this._parseVariable(this.$dom.data('variables'))
    this.variableSelectors = this._parseVariable(this.$dom.data('variable-selectors'));

    this.$dom.select2({
      placeholder: this.placeholder,
      allowClear: this.allow_clear
    });

    this._setTrigger()

    $(FORM_ID).on("submit", (function(e){
      $(':input', this).each(function() {
          this.disabled = !($(this).val());
      });
      // e.preventDefault()
    }))
  }

  destroy() {
    this.$dom.select2('destroy')
  }

  _setTrigger() {
    if (!this.triggerType) { return ; }
    if (this.triggerType === 'url' && this.triggerBaseurl.length > 0) {
      this.$dom.on('change', (e) => {
        const url = this._applyVariable(this.triggerBaseurl)
        Turbolinks.visit(url)
      })
      return;
    }
  }

  _parseVariable(str) {
    if (typeof str === 'object') {
      return str
    } else {
      let variables
      try {
        variables = JSON.parse(str.toString())
      } catch {
        variables = {}
      }
      return variables
    }
  }

  _applyVariable(str) {
    for (let key in this.variableSelectors) {
      const $dom = $(this.variableSelectors[key]) || ''
      const regex = new RegExp(`\#\{${key}\}`, 'g')
      str = str.replace(regex, $dom.val())
    }
    for (let key in this.variables) {
      const value = this.variables[key] || ''
      const regex = new RegExp(`\#\{${key}\}`, 'g')
      str = str.replace(regex, value)
    }

    return str
  }

}

let instances = []

$(document).on('turbolinks:load', function() {
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    instances.push(new FormSelect2(dom))
  })
})

$(document).on('turbolinks:before-cache', function() {
  instances.forEach(instance => {
    instance.destroy()
    instance = null
  })
  instances = []
})

export default FormSelect2
