import $ from 'jquery'

// 偵測device，決定要下載的url
// attrs:
//  data-download-app
//  data-google-play-url
//  data-apple-url

const SELECTOR = '[data-download-app]'
const ATTR_GOOGLE_PLAY = 'google-play-url'
const ATTR_APPLE = 'apple-url'

const deviceRules = {
  // Windows Phone must come first because its UA also contains "Android"
  'Windows Phone': (userAgent) => (/windows phone/i.test(userAgent)),
  'Android': (userAgent) => (/android/i.test(userAgent)),
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  'iOS': (userAgent) => (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream),
}

const storeUrlSamples = [
  {
    text: '用＠cosme App，緊貼美妝新品及抵買優惠消息！',
    apple: 'https://apps.apple.com/app/apple-store/id1468439350?pt=833223&ct=popup-new&mt=8',
    google: 'https://play.google.com/store/apps/details?id=jp.co.istyle.atcosme.goa&referrer=utm_source%3Dpop-up%26utm_medium%3Dpop-up%26utm_campaign%3Dpopup-new'
  },
  {
    text: '用＠cosme App，睇哂港台日用家的產品使用心得！',
    apple: 'https://apps.apple.com/app/apple-store/id1468439350?pt=833223&ct=popup-review&mt=8',
    google: 'https://play.google.com/store/apps/details?id=jp.co.istyle.atcosme.goa&referrer=utm_source%3Dpop-up%26utm_medium%3Dpop-up%26utm_campaign%3Dpopup-review'
  },
  {
    text: '用＠cosme App，就能參加各式各樣美妝產品試用活動！',
    apple: 'https://apps.apple.com/app/apple-store/id1468439350?pt=833223&ct=popup-new&mt=8',
    google: 'https://play.google.com/store/apps/details?id=jp.co.istyle.atcosme.goa&referrer=utm_source%3Dpop-up%26utm_medium%3Dpop-up%26utm_campaign%3Dpopup-gift'
  }
]
let storeUrl = storeUrlSamples[Math.floor(Math.random()*storeUrlSamples.length)];

const getCurrentDevice =() => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return Object.keys(deviceRules)
                .find((device) => deviceRules[device](userAgent));
}
class DownloadApp {
  constructor(dom) {
    this.$dom = $(dom)
    this.googlePlayUrl = this.$dom.data(ATTR_GOOGLE_PLAY) || storeUrl.google
    this.appleUrl = this.$dom.data(ATTR_APPLE) || storeUrl.apple

    this.$dom.on('click', (e) => {
      e.preventDefault()
      this._onClick()
    })
  }

  // 自動偵測device
  // ref: https://stackoverflow.com/a/21742107
  // return 'Window Phone', 'Android', 'iOS'

  _getDownloadUrl() {
    return getCurrentDevice() === 'iOS' && this.appleUrl || this.googlePlayUrl
  }

  _onClick() {
    const url = this._getDownloadUrl()
    if (url) {
      // location.href = url
      window.open(url, '_blank')
    }
  }
}

const gotoAppStore = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const hasShow = sessionStorage.getItem('appPopupShow');
  if (!isMobile || hasShow) return

  const url = getCurrentDevice() == 'iOS' ? storeUrl.apple : storeUrl.google
  sessionStorage.setItem('appPopupShow', true);
  Swal.fire({
    position: 'bottom-end',
    text: storeUrl.text,
    showCancelButton: true,
    confirmButtonText: '開啟',
    cancelButtonText: '取消'
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = url;
      gtag('event', 'click', {'event_category': 'gotoAppStore','event_label': storeUrl.text});
    }
  })
}

$(document).on('turbolinks:load', function() {
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    new DownloadApp(dom)
  })

  gotoAppStore();

})

export default DownloadApp
