import $ from 'jquery'

// ref: https://reed.github.io/turbolinks-compatibility/doubleclick_for_publishers.html
class Gpt {
  constructor() {
    this.slots = {}
    window.googletag = googletag || {}
    window.googletag.cmd = googletag.cmd || []

    $(document).on('turbolinks:before-cache', () => {
      this.clearAds()
    })

    $(document).on('turbolinks:load', () => {
      this.evaluate()
    })
    this.evaluate()
  }

  evaluate() {
    document.querySelectorAll('.gpt-ad').forEach(slot => {
      const $slot = $(slot)
      const cachedSlot = this.slots[$slot.data('gpt-div-id')]
      // this.defineSlot($slot)
      if ( cachedSlot ) {
        this.refreshSlot(cachedSlot)
      } else {
        this.defineSlot($slot)
      }
    })
  }

  defineSlot($slot) {
    const divId = $slot.data('gpt-div-id')
    const path = $slot.data('gpt-path')
    const dimensions = $slot.data('gpt-dimensions')

    googletag.cmd.push(() => {
      const slot = googletag.defineSlot(path, dimensions, divId).addService(googletag.pubads())
      googletag.pubads().setTargeting('UC_BRD_ID', $("[brand-id]").attr("brand-id"))
                        .setTargeting('UC_ATTR_ID', $("[tag-id]").attr("tag-id"));

      googletag.pubads().enableSingleRequest()
      googletag.enableServices()
      googletag.pubads().addEventListener('slotRenderEnded', (el => this.checkBanner(el, slot, divId)));
      googletag.pubads().addEventListener('slotRenderEnded', (el => {
        $('.dfpCloseBtn').after().on('click', (e) => {
          $(e.target).remove()
        })
      }));
      googletag.display(divId)
      this.slots[divId] = slot
    })
  }

  refreshSlot(slot) {
    googletag.cmd.push(() => {
      googletag.pubads()
               .setTargeting('UC_BRD_ID', $("[brand-id]").attr("brand-id"))
               .setTargeting('UC_ATTR_ID', $("[tag-id]").attr("tag-id"))
               .refresh([slot])
    })
  }

  clearAds() {
    googletag.cmd.push(() => {
      googletag.pubads().clear()
    })
  }

  checkBanner(el, slot, elm) {
    //  ref: https://stackoverflow.com/questions/40154738/google-publisher-tag-registering-to-events
    if (el.slot === slot) {
      if ($(`#${elm}`).find('iframe').length === 0) {
        $(`#${elm}`).remove()
      }
    }
  }
}

$(function() {
  new Gpt()
  // $('.dfpCloseBtn').after().on('click', (e) => {
  //   $(e.target).remove()
  // })
})
