import $ from 'jquery'

const SELECTOR = '[data-form-reset-select2]'

class FormResetSelect2 {
  constructor(dom) {
    this.$dom = $(dom)
    this.selects = this.$dom.data('selects')

    if (this.selects) {
      this.$dom.on('click', () => {
        setTimeout(() => {
          $(this.selects).trigger('change')
        }, 0)
      })
    }
  }
}

$(document).on('turbolinks:load', () => {
  document.querySelectorAll(SELECTOR).forEach((dom) => {
    new FormResetSelect2(dom);
  })
})
